<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <UNotifications />
</template>
<script setup lang="ts">
useState('breadcrumbs', () => {
    return [
        {
            label: 'Home',
            path: '/',
            to: '/',
            icon: 'front/home'
        }
    ]
});
</script>
<style>
.page-enter-active,
.page-leave-active {
    transition: all .3s ease-in-out;
}
.page-enter-from,
.page-leave-to {
    filter: blur(5px);
}
</style>
