import { default as PrismicPrevieweeoi5FGgd4Meta } from "/Users/slavachekanovskiy/Projects/ocautoaid/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as _91_46_46_46slug_934jdPbj9FL6Meta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/[...slug].vue?macro=true";
import { default as _91uid_935HdhCeWW2qMeta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/posts/[uid].vue?macro=true";
import { default as indexEkQpGE1KGsMeta } from "/Users/slavachekanovskiy/Projects/ocautoaid/pages/posts/index.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/preview",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/[...slug].vue")
  },
  {
    name: "posts-uid",
    path: "/posts/:uid()",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/posts/[uid].vue")
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/Users/slavachekanovskiy/Projects/ocautoaid/pages/posts/index.vue")
  }
]